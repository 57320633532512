import React, { Component, useEffect, useState, useRef } from "react";
import {Modal, Button, Header, Icon, Form, TextArea} from 'semantic-ui-react';
import styled from 'styled-components';
import {useGlobalState} from 'globalstate';
import SmartImage from 'components/SmartImage';
import {has} from 'lodash';

const StyledModal = styled(Modal)
`
    .ui.form label {
            display: block;
    margin: 0 0 .5rem 0;
    
    color: white;
    font-size: .92857143em;
    font-weight: 500;
    text-transform: none;
        
    }
    .explain {
      padding:0 15px;
      margin-bottom:10px;
    }
`

const CloseButton = styled.div
`
position:absolute;
top:16px;
right:0;
`

function ImageView(props){
  const [{browser}, dispatch] = useGlobalState();
  
      
   
    return <StyledModal
        open={props.open}
        basic
        size='small'
      >
      
        {has(props.project, "name") && <Header icon='browser' content={props.project.name} />}
        <CloseButton onClick={()=>props.onModalClose()}><Icon name='close' size="big"/></CloseButton>
        <div className="explain">This image can pan.  Use your finger or mouse to slide image.</div>
        <Modal.Content scrolling>
          {has(props.image, "imageKey") && <SmartImage className="fullscreen-image" size={900} imageKey={props.image.imageKey} /> }
        </Modal.Content>
      </StyledModal>
    
}

export default ImageView;