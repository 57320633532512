import React, { Component } from 'react'
import { Visibility, Loader } from 'semantic-ui-react'
import { decode as atob, encode as btoa } from 'base-64'
import styled, { keyframes } from 'styled-components';
const pulse = keyframes `
 0% {
      opacity: 1;
    }
     50% {
      opacity: 0.4;
    }
`

const fadeIn = keyframes `
    from { opacity: 0; }
    to   { opacity: 1; }
`

const BackgroundImage = styled.div `    
    width: 100%;
    height: 100%;
    background-image: url(${props => props.imageurl});
    background-size: cover !important;
    background-position: 50% 50% !important;
    background-repeat: no-repeat !important;
    display:block;
    position:absolute;
    animation:${fadeIn} .5s;
    z-index:1;
    &.top {
        background-position: 50% 0% !important;
    }
`

const LoaderBackground = styled.div `
    width:100%;
    height:100%;
    background-color:#dfdfdf;
    display:flex;
    flex-direction:column;
    text-align:center;
    align-items:center;
    position:relative;
    top:0;
    left:0;
    svg{
        font-size:72px;
        color:white;
        flex:1;
        animation: ${pulse} 1.2s infinite;
                 animation-fill-mode: both;
    }
`

const ImageWrapper = styled.div `
position:relative;
    padding-bottom:67%;
    display:block;
    border-radius:2px;
    overflow:hidden;
    
    `

const Img = styled.img `
position:relative;
z-index:1;
animation:${fadeIn} .5s;
`



class SmartImage extends Component {
    state = { showImage: false, loading: false, url: null, error: false };

    getImage() {
        if (this.state.error) return null;
         var CloudFrontUrl = "https://d2xyzyk661n2vc.cloudfront.net";
        
        var key = this.props.imageKey;
        const imageRequest = JSON.stringify({
            "bucket": "acgi-project-imagesprod-prod",
            "key": "public/" + key,
            "edits": {
                "resize": {
                    "width": this.props.size || 400,
                    "fit": this.props.fit || "cover"
                }
            }
        })
        const url = `${CloudFrontUrl}/${btoa(imageRequest)}`;
        console.log("REQUESTED URL",url)
        this.setState({
            url
        })
        return url;
    }

    componentDidMount() {
        if (this.props.broken) {
            this.setState({ error: true, showImage: true });
        }
    }

    handleImageLoaded = () => {
        this.setState({ loading: false });
    }

    handleImageError = () => {
        this.setState({ loading: false, error: true });
    }

    showState = () => {
        if (this.state.error) return <LoaderBackground  className={this.props.className}>Loading Image</LoaderBackground>;
        if (this.props.loader && this.state.loading) return <LoaderBackground  className={this.props.className}>Loading Image</LoaderBackground>;
        else {
            if (this.props.background) return <LoaderBackground className={this.props.className}><BackgroundImage  imageurl={this.state.url} /></LoaderBackground>;
            else return <Img className={this.props.className} src={this.state.url} />
        }
    }

    showImage = () => {
        this.image = new Image();
        this.image.src = this.getImage();
        this.image.onload = this.handleImageLoaded;
        this.image.onerror = this.handleImageError;
        this.setState({
            showImage: true,
            loading: true
        })


    }
    render() {
        if (this.state.showImage) return this.showState();
        else return <Visibility as="div" className="card-image-fill" onOnScreen={this.showImage} fireOnMount={true}>
                        <LoaderBackground>Loading Image</LoaderBackground>
                    </Visibility>
    }
}

export default SmartImage;
