import {listServices, listPromos, listReviews, listLeads, getServiceBySlug} from 'graphql/queries'; 
import {listProjects} from 'graphql/savedQueries'; 
import Amplify, {API,graphqlOperation} from 'aws-amplify';
import {filter, includes, find} from 'lodash'

export const getAllData = async (dispatch) =>{
    await LoadServices(dispatch)
    // await LoadProjects(dispatch)
    // await LoadReviews(dispatch)
    // await LoadPromos(dispatch)
    // await LoadLeads(dispatch)
}

export const LoadServices = async (callback) =>{
   // dispatch({type:serviceActions.SET_SERVICES_ISLOADED, value:false})
    var servicesresp = await API.graphql(graphqlOperation(listServices, {limit:99}));
    var projectresp = await API.graphql(graphqlOperation(listProjects, {limit:99}));
    let serviceList = Object.values(servicesresp.data.listServices.items);
    let projectList = Object.values(projectresp.data.listProjects.items);
     const getImageKey = (service)=>{
         //console.log("Service", service);
        // console.log(projectList)
         let servprojects = filter(projectList, (proj)=>{return includes(proj.services, service.id)});
         
         //console.log('project found', servprojects)
         if(servprojects.length > 0){
         let imagekey = servprojects[0].images.items[0].imageKey
         //console.log("Found project image", imagekey);
         return imagekey;
         }
         return null
     }
    let services = serviceList.map(service=>Object.assign({...service, imageKey:getImageKey(service)}))
    callback(services)
    //dispatch({type:serviceActions.SET_SERVICES, payload:response.data.listServices.items})
}


export const LoadProjects = async (serviceslug, callback) =>{
    //Get service
    var servicesresp = await API.graphql(graphqlOperation(listServices, {limit:99}));
    let servicelist = Object.values(servicesresp.data.listServices.items);
    let service = find(servicelist, ["slug", serviceslug]);
    
    
    //Get Projects for service
    var projectresp = await API.graphql(graphqlOperation(listProjects, {limit:99}));
    let projectList = Object.values(projectresp.data.listProjects.items);
    let servprojects = filter(projectList, (proj)=>{return includes(proj.services, service.id)});
    
    callback(service, servprojects);
}


// export const LoadProjects = async (dispatch) =>{
//     dispatch({type:projectActions.SET_PROJECTS_ISLOADED, value:false})
//     var response = await API.graphql(graphqlOperation(listProjects, {limit:99}));
//     dispatch({type:projectActions.SET_PROJECTS, payload:response.data.listProjects.items})
// }

// export const LoadReviews = async (dispatch) =>{
//     var response = await API.graphql(graphqlOperation(listReviews, {limit:99}));
//     dispatch({type:reviewActions.SET_REVIEWS, payload:response.data.listReviews.items})
// }

// export const LoadPromos = async (dispatch) =>{
//     var response = await API.graphql(graphqlOperation(listPromos, {limit:99}));
//     dispatch({type:promoActions.SET_PROMOS, payload:response.data.listPromos.items})
// }

// export const LoadLeads = async (dispatch) =>{
//     var response = await API.graphql(graphqlOperation(listLeads, {limit:99}));
//     dispatch({type:leadActions.SET_LEADS, payload:response.data.listLeads.items})
// }