import React, { Component } from "react";
import {Menu, Image,  Container, Sticky, Header, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import GoogleIcon from 'components/social/GoogleIcon';
import HouzzIcon from 'components/social/HouzzIcon';
import FacebookIcon from 'components/social/FacebookIcon';
import YelpIcon from 'components/social/YelpIcon';
import {useGlobalState} from 'globalstate';

const MenuWrapper = styled.div
`
    position:fixed;
    top:40px;
    width:100%;
    display:flex;
    flex-direction:row;
    background-color:white;
    min-height:60px;
    line-height:60px;
    z-index:10;
    border-bottom:1px solid #dfdfdf;
    .leftbar {
        flex:1;
        text-align:center;
        padding-left:20px;
        .title {
            font-size:20px;
            .image {
                    height: 27px;
    vertical-align: middle;
    margin-right: 10px;
    margin-top: -10px;
}
            }
        }
    }
    .rightbar {
        flex:1;
        text-align:right;
        padding-right:20px;
        .menu {
            font-size:18px;
            a {
                padding:0 8px;
                color:black;
            }
        }
    }
    
    &.extraSmall {
    flex-direction:column;
        min-height: 40px;
    line-height: 40px;
        .leftbar { 
            margin:0;
            padding:0;
            .title {
                font-size:16px;
            }
            img {
                margin-left:-20px;
            }
        }
        .contact {
            flex-direction:row;
            display:flex;
            justify-content: space-evenly;
            align-items: center;
        }
    }
     a {
      font-size:16px;
      padding-top:2px;
    }
    &> * {
      padding:0 8px;
    }
    .social_icon {
        
    }
    .social_icon .google_state_1{
        color:#666;
    }
    .social_icon .google_state_2{
        color:#dc4e41;
    }
    
    .social_icon .houzz_state_1{
        color:#666;
    }
    .social_icon .houzz_state_2{
        color:#7ac142;
    }
    
    .social_icon .yelp_state_1{
        color:#666;
    }
    .social_icon .yelp_state_2{
        color:#d32323;
    }
    
    .social_icon .facebook_state_1{
        color:#666;
    }
    .social_icon .facebook_state_2{
        color:#3b5998;
    }
`

export default function MainMenuBar () {
    const [{browser}, dispatch] = useGlobalState();
 
    return (
        <MenuWrapper className={browser.mediaType}>
            <div className="leftbar">
                <Link to="/" className="title">
                <Image centered src="https://acgi-homes.s3-us-west-2.amazonaws.com/ACGI+Logo+Final.png" alt="ACGI Homes" />
                <span>Andruszko Construction Group Inc.</span>
                </Link>
            </div>
            {browser.mediaType == "extraSmall" &&
            <div className="contact">
                <a href="tel:5034311194">503.431.1194</a>
                <Link to="/contact"><Icon name='mail' size="large"/></Link>
                <YelpIcon />
                <FacebookIcon />
                <GoogleIcon />
                <HouzzIcon />
            </div>
            }
      </MenuWrapper>
    );
  
}