import React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import Home from "containers/Home";
import Promotions from "containers/Promos";
import Projects from "containers/Projects";
import ServiceView from 'containers/ServiceView';
import About from "containers/About";
import Contact from "containers/ContactUs";

  
  const routes = [
      { path: '/',
        exact: true,
        component: Home
      },
      { path: '/promos',
        exact:true,
        component: Promotions
      },
      { path: '/project/:projectid',
        exact:true,
        component: Projects
      },
      { path: '/service/:servicetype',
        exact:true,
        component: ServiceView
      },
      { path: '/about',
        exact:true,
        component: About
      },
      { path: '/contact',
        exact:true,
        component: Contact
      }
    ];
    
    
    // A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

function Routes(){
  return <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
}

export default Routes;