import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import BrowserRouter from 'react-router-dom/BrowserRouter';
import GlobalStateProvider from 'globalstate';
import GlobalStyle from 'globalstyles';
import * as serviceWorker from './serviceWorker';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

const BrowserRouter = require("react-router-dom").BrowserRouter
Amplify.configure(awsconfig);
ReactDOM.render(

    <GlobalStateProvider>
        <GlobalStyle />
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </GlobalStateProvider>,
    document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
