import React, { Component } from "react";
import {Icon, Reveal} from 'semantic-ui-react';

export default class YelpIcon extends Component {
  render() {
    return (
            <div className="social_icon">
                <Reveal animated='fade' as="a" href="https://www.yelp.com/biz/andruszko-construction-group-beaverton?osq=Acgi" target="_blank">
                    <Reveal.Content visible className="yelp_state_1">
                      <Icon name='yelp' size="large"/>
                    </Reveal.Content>
                    <Reveal.Content hidden className="yelp_state_2">
                      <Icon name='yelp' size="large"/>
                    </Reveal.Content>
                  </Reveal>
            </div>
    );
  }
}