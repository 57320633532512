import React from 'react';
import {Segment, Container, Grid, Header, List, Divider, Image} from 'semantic-ui-react';
import SmartImage from 'components/SmartImage';
import styled from 'styled-components';

const RewardBlock = styled.div
`
  margin-bottom:20px;
  .reward-image {
        margin: 5px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 4px white;
    display: inline-block;
    height: 80px;
    overflow: hidden;
    img{
      height:80px;
      border-radius:6px;
    }
  }
`

class Footer extends React.Component {
        render(){
           return <Segment inverted vertical style={{ margin: '5em 0em 0em', padding: '5em 0em' }}>
      <Container textAlign='center'>
        <RewardBlock className="rewards">
            <Header inverted as='h4' content='Awards' />
            <div  className="reward-image" ><SmartImage imageKey="bestofhouzz-2019.png" size={80} /></div>
            <div  className="reward-image" ><SmartImage  imageKey="bestofhouzz-2020.jpg" size={80} /></div>
            <div  className="reward-image" ><SmartImage  imageKey="EPAcertified.png" size={80} /></div>
        </RewardBlock>
        <Grid divided inverted centered>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header inverted as='h4' content='Popular Services' />
              <List link inverted>
                <List.Item as='a'>Painting</List.Item>
                <List.Item as='a'>Carpentry</List.Item>
                <List.Item as='a'>Remodeling</List.Item>
                <List.Item as='a'>Decking</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={8}>
              <Header inverted as='h4' content='Contact ACGI' />
              <List link inverted>
                <List.Item as='a' href="/contactus">Contact Us Online</List.Item>
                <List.Item as='a' href="mailto:info@acgihomes.com" >info@acgihomes.com</List.Item>
                <List.Item as='a' href="tel:5034311194" >503.431.1194</List.Item>
                <List.Item >CCB # 212838</List.Item>
              </List>
              
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider inverted section />
        <Image centered src="https://s3-us-west-2.amazonaws.com/acgi-homes/logo+inverted.png" alt="ACGI Homes" />
        <List horizontal inverted divided link>
          <List.Item as='a' href='/sitemap'>
            Site Map
          </List.Item>
        </List>
      </Container>
    </Segment>
        }
}

export default Footer;