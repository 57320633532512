import React, { Component } from "react";
import {Icon, Reveal} from 'semantic-ui-react';

export default class HouzzIcon extends Component {
  render() {
    return (
            <div className="social_icon">
                <Reveal animated='fade' as="a" href="https://www.houzz.com/pro/bobby-andruszko/andruszko-construction-group-inc" target="_blank">
                    <Reveal.Content visible className="houzz_state_1">
                      <Icon name='houzz' size="large"/>
                    </Reveal.Content>
                    <Reveal.Content hidden className="houzz_state_2">
                      <Icon name='houzz' size="large"/>
                    </Reveal.Content>
                  </Reveal>
            </div>
    );
  }
}