import React, { Component } from "react";
import {Image, Container,  Grid,  Header, Icon, Divider} from 'semantic-ui-react';
import Spacer from 'components/Spacer';
import styled from 'styled-components';

const AboutWrapper = styled.div
`
    text-align: left;
    font-size:16px;
    line-height:24px;
    p, .header {
        padding:20px;
    }
`

export default class About extends Component {
  render() {
    return (
            <AboutWrapper>
                <Spacer height="30" />
                <Grid columns={16}>
                <Grid.Row>
                <Grid.Column width={6}>
                    
                </Grid.Column>
                <Grid.Column width={10}>
                <Header as='h2' textAlign='center'>
                    <Icon name='users' circular float="left" />
                    <Header.Content>Team Commitment</Header.Content>
                  </Header>
                  <div>We face a lot of challenges as a small company, however my employees and I believe that with God anything is possible.  We have chosen to put our lives into his hands in faith and as we all walk with him he takes care of our clients & us.</div>
                  <Header size="medium">My employees and I all have young children & we do our absolute best to provide for them, we work hard every day & put forth a unified effort to help our clients & kids.</Header>
                
                </Grid.Column>
                
                </Grid.Row>
                </Grid>
                  <Spacer height="30" />
                <Divider />
                <Spacer height="30" />
                <Grid verticalAlign='middle'>
                <Grid.Column width={10}><Header size="medium">Established in 2009.</Header>
            <p>I started my business at the height of the recession with my tax returns.  I'm a 3rd generation contractor.  I paint because I love it, I'm passionate about what I do & I would rather serve others than the alternative.  I seek to enrich my customers lives by giving them quality craftsmanship on every project we are awarded.</p>
             <p>As you consider hiring a contractor for your next project, please consider ACGI for your General Contracting needs.  I'm to the point & provide fair & competitive pricing for All of my clients.  We are conscientious about a clean job site, appearance, professionalism & we seek the perfection in every job that we are awarded.  </p>
                   <Header size="medium">We would love to serve you in this capacity & we look forward to hearing from you.</Header>
      </Grid.Column>
                <Grid.Column width={6} className="centered"><Image src="https://s3-us-west-2.amazonaws.com/acgi-homes/ACGI+Logo+Final.png" alt="ACGI Homes" />   
          </Grid.Column>
                    </Grid>
                   <Spacer height="30" />
            <Divider />
            <Spacer height="30" />
 <Image size="medium"  floated='right' className="padded"  src="https://s3-us-west-2.amazonaws.com/acgi-homes/ProcessedServices/Selfies+%26+Crew/Bobby+and+son.jpg" alt="Bobby Andruszko" title="Bobby Andruszko"/>
             
            <Header as="h1">Meet the Business Owner</Header>
            <Header as="h3">Bobby Andruszko</Header>
            <p>Bobby Andruszko is a PSU Communications graduate, 3rd generation Oregon Contractor with 9 years in the industry.</p>
                    <p>I'm a father to a wonderful little boy named Isaiah O. Andruszko,  His nickname is Baby man and I'm Daddy Man.  He is 11 and is excited to be a contractor when he turns 27!</p>
             <br/>
             <br/>
        </AboutWrapper>
    );
  }
}