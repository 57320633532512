

import React, { useEffect, useState } from "react";
import { Form, Button, Label } from 'semantic-ui-react'
import {includes, reject, has, find, filter, pickBy} from 'lodash';
import {Link} from 'react-router-dom'
import styled from "styled-components"
import {useGlobalState} from 'globalstate';
import {LoadServices} from 'graphql/queryHandlers'
import {createLead} from 'graphql/mutations';
import { API, graphqlOperation} from 'aws-amplify';

const ContactFormWrapper = styled.div
`
  position:relative;
  margin-top:0px;
  padding:0 20px;
  form {
      text-align:left;
      .sent {
        background-color: green;
        color:white;
      }
  }
  
  
`

const ToggleButton = styled(Button)
`
  box-shadow:none !important;
  margin:2px !important;
  &.ui.button.selected {
    background-color:green !important;
    color:white !important;
    box-shadow:none !important;
    border-color:white !important;
  }
`

const Overlay = styled.div 
`
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:rgba(255,255,255, .9);
  transition:opacity .5s;
  opacity:0;
  pointer-events:none;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding:0 30px;
  &.open {
    opacity:1;
    
  }
  .overlaytext {
    color:black;
    font-size:18px;
    line-height:24px;
    
  }
`


function ContactUs () {
    const [{browser}, dispatch] = useGlobalState();
    const [services, setServices] = useState([]);
    const [sent, setSent] = useState(false);
 
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [selectedServices, setSelectedServices] = useState([]);
    const [message, setMessage] = useState("");
    const [newsletter, setNewsletter] = useState(true);
    const [formReady, setFormReady] = useState(false)
    
    
    useEffect(() => {
       LoadServices(setServices)
       
    }, []);
    
    useEffect(() => {
       const timeout = setTimeout(() => {
          setSent(false)
        }, 5000);

        return () => clearTimeout(timeout);
       
    }, [sent]);
    
    const handleChange = (e, { value }) => {
        console.log(e.target.name,value)
        formIsReady();
    }
    
    const clearForm = ()=>{
      setSent(true);
      setName("");
      setEmail("");
      setPhone("");
      setSelectedServices([]);
      setMessage("");
      setNewsletter(true);
      setFormReady(false);
    }
    
    const handleSubmit = async ()=>{
        //create object
        var leadObject = {
            name,
            phone,
            email,
            services:selectedServices,
            message,
            newsletter
        }
        console.log("object to send",leadObject);
        await API.graphql(graphqlOperation(createLead, {input:pickBy(leadObject)}));
        clearForm();
    }
    
    const toggleService = (name)=>{
      let service = find(services, ["name", name]);
      if(includes(selectedServices, name)){
        setSelectedServices(reject(selectedServices, function(s) {return s === name; }));
      } else {
        setSelectedServices([...selectedServices, service.name])
      }
    }
    
    const isSelected = (name)=>{
      if(includes(selectedServices, name)) return "selected"; else return "";
    }
    
    const formIsReady = ()=>{
      if(name.length > 0 && selectedServices.length > 0 && email.length > 0) setFormReady(true); else setFormReady(false);
      
    }
    
    if(services.length === 0) return null;
    
    //console.log("Services", services)
    
    let serviceList = Object.values(services);
 
    return (
      <ContactFormWrapper id="contactus">
        <div>
          <h1>How may we help you?</h1>
          <Form onSubmit={()=>handleSubmit()}>
          <Form.Input fluid label='Name' placeholder='Enter your full name' value={name} onChange={(e, {name, value})=>setName(value)}/>
          <Form.Input fluid label='Phone Number' placeholder='Enter your contact phone number' value={phone} onChange={(e, {name, value})=>setPhone(value)}/>
          <Form.Input fluid label='Email Address' placeholder='Enter your email' value={email} onChange={(e, {name, value})=>setEmail(value)}/>
          
            <h5>Services Needed</h5>
          {serviceList.map((service)=> <ToggleButton type="button" compact key={"serv_"+service.id} onClick={()=>toggleService(service.name)} className={isSelected(service.name)}>{service.name}</ToggleButton>)}
             
              <br/>
                <br/>
        <Form.TextArea label='How can we help you?' placeholder='Tell us what we can do to help...' value={message} onChange={(e, {name, value})=>{setMessage(value); formIsReady()}} />
        <Form.Checkbox label='Join our monthly newsletter with valuable homeowner info and deals' checked={newsletter} onChange={(e, {name, checked})=>setNewsletter(checked)}/>
        <div>We will do our best to contact you within 24 hrs.  Please <a href="tel:5034311194">call us</a> if you need immediate assistance.</div>
        <br/>
        <Form.Button className={sent && "sent"} fluid disabled={!formReady}>Submit</Form.Button>
      </Form>
        </div>
        <Overlay className={sent && "open"}><div className="overlaytext"><strong>Thank you!</strong><br/>We will do our best to contact you within 24 hrs.</div></Overlay>
      </ContactFormWrapper>
    );
  
}

export default ContactUs