import React, { useEffect } from 'react';
import ContactMenuBar from 'components/ContactMenuBar';
import MainMenuBar from 'components/MainMenuBar';
import Footer from 'components/Footer';
import {has} from 'lodash'
import Routes from './Routes';
import {useGlobalState} from 'globalstate';
import styled from 'styled-components';

const AppWrapper = styled.div 
`
  text-align: center;
  font-family: 'Open Sans', sans-serif !important;
display:flex;
flex-direction:column;
height:100%;
  position:relative;
  
.centered {
    text-align:center !important;
    align-self:center !important;
    align-content: center !important;
}

.dark {
    background-color:rgba(0,0,0,0.05);
}


img.ui.image {
    display:inline-block !important;
}
.outer_page .image_message_outer {
    position:relative;
    width:100%;
    height:300px;
    overflow:hidden;
}

.outer_page .image_message_outer .dark_float {
    background-color:rgba(0,0,0,0.6);
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
}

.outer_page .image_message_outer .over-logo {
    
    position:absolute;
    left:50%;
    transform:translate(-50%, -50%);
    top:50%;
    .image {
        width:360px;
    }
}

.outer_page .image_message_outer .hero_message{
    max-width:500px;
    background-color:rgba(255,255,255, 0.9);
    border:none;
    left:50%;
    border-radius:0;
    margin-left:-250px;
    bottom:20px;
    position:absolute;
    color:#333;
}

.outer_page .image_message_inner {
    white-space: nowrap;
    position:absolute;
    width:1821px;
    left:50%;
    transform:translateX(-50%);
    overflow:hidden;
}

.outer_page .hero_image{
    height:300px;
    display:inline-block;
}

.page {
    text-align: left;
    font-size:16px;
    line-height:24px;
}

.page .testimonials {
    text-align:center;
}

.page .ui.card.testimonial {
    min-width:300px;
    box-shadow:none;
    border:none;
    left:50%;
    transform:translateX(-50%);
}

.page .ui.card.testimonial .description {
    text-align:left;
}

.page .slider .slider-control-centerleft {
    display:none;
}
.page .slider .slider-control-centerright {
    display:none;
}
.page .slider .slider-control-bottomcenter {
    display:none;
}

img.padded {
    border: 1px solid #dfdfdf;
    padding: 5px;
}
`

const PageWrapper = styled.div
`
    position:relative;
    margin:0;
    margin-top:160px;
    flex:1;
`


function App () {
    const [{browser}, dispatch] = useGlobalState();
    useEffect(() => {
       
        dispatch({type:"INIT"})
      }, []);
      if(has(browser, "mediaType"))
    return (
     
      <AppWrapper>
    
        <ContactMenuBar />
        <MainMenuBar />
        <PageWrapper>
          <Routes />
        </PageWrapper>
        <Footer />
      </AppWrapper>
    );
    else return null
}

export default App;