import React, { useState, useEffect } from "react";
import {Image, Header, Card,  Label, Container, Grid,  Segment, Icon, Divider} from 'semantic-ui-react';
import Spacer from 'components/Spacer';
import {Link} from 'react-router-dom';
import Carousel from 'nuka-carousel';
import {capitalize} from 'lodash';
import reviewdata from "sitedata/testimonials.json";
import {LoadServices} from 'graphql/queryHandlers'
import styled from 'styled-components';
import {useGlobalState} from 'globalstate';

import SmartImage from 'components/SmartImage';
const AboutWrapper = styled.div
`
    text-align: left;
    font-size:16px;
    line-height:24px;
    p, .header {
        padding:5px 20px;
    }
    .mobile-fullWidth {
        width:100% !important;
        float:none;
        height:auto;
        display:block;
    }
`

const Section = styled(Container)
`
    position:relative;
    margin-top:${props=>props.head? props.head : "30px"}px;
    margin-bottom:${props=>props.foot? props.foot : "30px"}px;
    h1.ui.header {
        font-size:18px;
        .sub.header {
            font-size:14px;
        }
    }
    text-align: left;
    font-size:16px;
    line-height:24px;
    p, .segment.vertical {
        padding:5px 20px;
    }
    h2.ui.header {
        font-size:18px;
    }
    &.about {
        .mobile-fullwidth {
            width:100%;
        }
    }
`

const ServiceBlock = styled(Link)
`
    position:relative;
    width:auto;
    height:60px;
    background-color:grey;
    border-radius:6px;
    margin:2.5px;
    overflow:hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .service-image {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index:1;
        
    }
    .service-title {
        position:relative;
        z-index:5;
        color:white;
        padding:10px;
        text-shadow: 0px 0px 2px #2d2d2d;
    }
    .dark-overlay {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-color:black;
        opacity:0.3;
        z-index:3;
    }
`

const ServiceGrid = styled.div
`
    position:relative;
    display:grid;
    grid-template-columns: 1fr 1fr;
`




export default function Home () {
    const [{browser}, dispatch] = useGlobalState();
    const [services, setServices] = useState([]);
 
    useEffect(() => {
       LoadServices(setServices)
       
    }, []);
    return (
            <div className="outer_page">
            {browser.mediaType !== "extraSmall" &&
            <div className="image_message_outer">
                <div className="image_message_inner">
                    
                    
                </div>
                <div className="dark_float"/>
                <div className="over-logo">
                        <Image centered src="https://acgi-homes.s3-us-west-2.amazonaws.com/logo+inverted.png" alt="ACGI Homes" />
                    </div>
            </div>
            }
            
            <Section head={0} foot={40}>
                <Header as="h1">ACGI is ready for your next project
                    <Header.Subheader>Select a service below to view our recent projects.</Header.Subheader>
                </Header>
                <Spacer height="10" />
                <ServiceGrid>
                    {services.map(service=><ServiceBlock to={"service/"+service.slug} key={service.id}><div className="service-title">{service.name}</div><SmartImage background className="service-image" imageKey={service.imageKey} /><div className="dark-overlay" /></ServiceBlock>)}
                </ServiceGrid>
                
            </Section>
            
            <Section className="page">
            
                <Spacer height="30" />
                <Segment basic className="testimonials">
                  <Header>Customer Reviews</Header>
                <Carousel 
                    autoplay 
                    autoplayInterval={5000}
                    wrapAround
                    cellAlign="center"
                    >
                    {reviewdata.testimonials.map((test)=>
                        <Card key={test.name} className="testimonial">
                            <Card.Content description={test.text} className="description" />
                            <Card.Content extra>
                            {test.services.map(service => (
                                <Label key={service} size="medium">
                                  {capitalize(service)}
                                </Label>
                              ))}
                      </Card.Content>
                            <Card.Content extra>
                              
                              <Image avatar src={test.image} />
                              <Label content={test.name} />
                                      <Label content={test.date} />
                            </Card.Content>
                          </Card>
                    )}
                </Carousel>
                </Segment>
            </Section>
            <Section className="overview">
                
                <Grid columns={16} stackable>
                <Grid.Row>
                <Grid.Column width={10}>
                <Header as='h2' textAlign='center'>
                    <Icon name='thumbs up outline' circular float="left" />
                    <Header.Content>Brief Corporate Overview</Header.Content>
                  </Header>
                    <Segment vertical>I expanded my skill set and connections to handle the broadest range of services possible over the course of the last 11 years in business.</Segment>
                    <Segment vertical>Superior Quality Control on every job from the owner, Bobby Andruszko.</Segment>
                    <Segment vertical>ACGI provides Quality Craftsmanship on every project.</Segment>
                    <Segment vertical>Cleanliness- We clean up as we go.</Segment>
                    <Segment vertical>Personal  Relationships with local building materials & paint suppliers.</Segment>
                    <Segment vertical>Mentored  by 2 local contractors with an accumulation of 80 years of experience in the trades.</Segment>
                    <Segment vertical>Interior Design Services available upon request.</Segment>
                </Grid.Column>
                
                </Grid.Row>
                </Grid>
                 
        </Section>
        <Section className="guarantee">
                
                <Grid columns={16} stackable>
                <Grid.Row>
                <Grid.Column width={10}>
                <Header as='h2' textAlign='center'>
                    <Icon name='handshake outline' circular float="left" />
                    <Header.Content>ACGI Guarantee to our clients</Header.Content>
                  </Header>
                    <Segment vertical>We stand behind our craftsmanship.  If you are not completely satisfied with our work, you can simply contact us Here, and tell us how we can improve our services tailored to your request.</Segment>
                    <Segment vertical>Our name is on our work, that’s why we are called ACGI.  We stand by our name & Rep. in the community & we keep our word by providing quality craftsmanship every project. Our motto is “Our work speaks for itself on every project”.</Segment>
                    <Segment vertical>Solid customer service skills, precise attention to detail, fair & competitive pricing on personalized estimates tailored to the specifications we discuss on site.</Segment>
              
                </Grid.Column>
                
                </Grid.Row>
                </Grid>
                 
        </Section>
       <Section className="about">
                <Spacer height="30" />
                <Grid columns={16} stackable>
                <Grid.Row>
                <Grid.Column width={6}>
                    
                </Grid.Column>
                <Grid.Column width={10}>
                <Header as='h2' textAlign='center'>
                    <Icon name='users' circular float="left" />
                    <Header.Content>Team Commitment</Header.Content>
                  </Header>
                  <p>We face a lot of challenges as a small company, however my employees and I believe that with God anything is possible.  We have chosen to put our lives into his hands in faith and as we all walk with him he takes care of our clients & us.</p>
                  <Header size="medium">My employees and I all have young children & we do our absolute best to provide for them, we work hard every day & put forth a unified effort to help our clients & kids.</Header>
                
                </Grid.Column>
                
                </Grid.Row>
                </Grid>
                  <Spacer height="30" />
                <Divider />
                <Spacer height="30" />
                <Grid verticalAlign='middle' stackable>
                    <Grid.Column width={10}><Header size="medium">Established in 2009.</Header>
                        <p>I started my business at the height of the recession with my tax returns.  I'm a 3rd generation contractor.  I paint because I love it, I'm passionate about what I do & I would rather serve others than the alternative.  I seek to enrich my customers lives by giving them quality craftsmanship on every project we are awarded.</p>
                        <p>As you consider hiring a contractor for your next project, please consider ACGI for your General Contracting needs.  I'm to the point & provide fair & competitive pricing for All of my clients.  We are conscientious about a clean job site, appearance, professionalism & we seek the perfection in every job that we are awarded.  </p>
                        <Header size="medium">We would love to serve you in this capacity & we look forward to hearing from you.</Header>
                    </Grid.Column>
                    <Grid.Column width={6} className="centered"><Image src="https://s3-us-west-2.amazonaws.com/acgi-homes/ACGI+Logo+Final.png" alt="ACGI Homes" /></Grid.Column>
                </Grid>
                <Spacer height="30" />
                <SmartImage className="mobile-fullwidth" imageKey="Bobby and son.jpg" size={300} />
                
                <Header as="h1">Meet the Business Owner</Header>
                <Header as="h3">Bobby Andruszko</Header>
                <p>Bobby Andruszko is a PSU Communications graduate, 3rd generation Oregon Contractor with 11 years in the industry.</p>
                <p>I'm a father to a wonderful boy named Isaiah O. Andruszko,  His nickname is Baby man and I'm Daddy Man.  He is 11 and is excited to be a contractor when he turns 27!</p>
             <br/>
             <br/>
        </Section>
      </div>
      
    );
  
}