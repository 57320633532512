export const listProjects = `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      services
      description
      images {
          items {
            id
            title
            imageKey
          }
        nextToken
      }
    }
    nextToken
  }
}
`;