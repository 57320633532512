import React, { Component } from "react";
import {Icon, Reveal} from 'semantic-ui-react';

export default class FacebookIcon extends Component {
  render() {
    return (
            <div className="social_icon">
                <Reveal animated='fade' as="a" href="https://www.facebook.com/Andruszkoconstructiongroupinc/" target="_blank">
                    <Reveal.Content visible className="facebook_state_1">
                      <Icon name='facebook f' size="large"/>
                    </Reveal.Content>
                    <Reveal.Content hidden className="facebook_state_2">
                      <Icon name='facebook f' size="large"/>
                    </Reveal.Content>
                  </Reveal>
            </div>
    );
  }
}