import React, { createContext, useContext, useReducer } from 'react';

import { calculateResponsiveState, responsiveStateReducer } from 'redux-responsive'

const initialState = {
    browser: {}
};


const mainReducer = ({ browser }, action) => {
    console.log("GLOBAL STATE UPDATING", action)
    let browseraction = calculateResponsiveState(window);
    console.log("browser",browseraction)
    return {
        browser: responsiveStateReducer(browser, browseraction)
    }
};


const GlobalStateProvider = ({ children }) => (

    <GlobalStateContext.Provider value={useReducer(mainReducer, initialState)}>
    {children}
  </GlobalStateContext.Provider>
);
export const useGlobalState = () => useContext(GlobalStateContext);
export const GlobalStateContext = createContext();
export default GlobalStateProvider;
