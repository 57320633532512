import React, { Component } from "react";
import GoogleIcon from 'components/social/GoogleIcon';
import HouzzIcon from 'components/social/HouzzIcon';
import FacebookIcon from 'components/social/FacebookIcon';
import YelpIcon from 'components/social/YelpIcon';
import {Link} from 'react-router-dom';
import {useGlobalState} from 'globalstate';
import styled from 'styled-components'

const ContactMenuWrapper = styled.div
`
  position:fixed;
  top:0;
  width:100%;
  height:40px;
  padding-top:8px;
  padding-right:10px;
  display:flex;
  z-index:9;
  flex-direction:row;
  justify-content:flex-end;
    margin:0;
    border:none;
    box-shadow:none;
    background-color:#f4f4f4;
    line-height:20px;
    text-align:right;
    a {
      font-size:16px;
      padding-top:2px;
    }
    &> * {
      padding:0 8px;
    }
.social_icon {
    
}
.social_icon .google_state_1{
    color:#666;
}
.social_icon .google_state_2{
    color:#dc4e41;
}

.social_icon .houzz_state_1{
    color:#666;
}
.social_icon .houzz_state_2{
    color:#7ac142;
}

.social_icon .yelp_state_1{
    color:#666;
}
.social_icon .yelp_state_2{
    color:#d32323;
}

.social_icon .facebook_state_1{
    color:#666;
}
.social_icon .facebook_state_2{
    color:#3b5998;
}
 
`

const Promotion = styled.div
`
  position:absolute;
  left:10px;
  top:10px;
  font-size:18px;
  z-index:6;
  color:orange;
  font-weight:bold;
    &.extraSmall {
      position:relative;
      left:auto;
      top:auto;
      text-align:center;
      width:100%;
    }
  
`

export default function ContactMenuBar () {
  const [{browser}, dispatch] = useGlobalState();
  if(browser.mediaType === "extraSmall"){
    return (
      <ContactMenuWrapper>
        <Promotion className={browser.mediaType}>15% of Interior Painting thru April</Promotion>
        
        
      </ContactMenuWrapper>
      )
  } else {
    return (
      <ContactMenuWrapper>
        <Promotion>15% of Interior Painting thru April</Promotion>
        <a href="tel:5034311194">503.431.1194</a>
        <Link to="/contact">Contact Us</Link>
        <YelpIcon />
        <FacebookIcon />
        <GoogleIcon />
        <HouzzIcon />
        
      </ContactMenuWrapper>
    );
  }
}