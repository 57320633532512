import React, { Component } from "react";
import {Icon, Reveal} from 'semantic-ui-react';

export default class GoogleIcon extends Component {
  render() {
    return (
            <div className="social_icon">
                 <Reveal animated='fade' as="a" href="https://www.google.com/search?q=acgihomes&oq=acgihomes" target="_blank">
                    <Reveal.Content visible className="google_state_1">
                      <Icon name='google' size="large"/>
                    </Reveal.Content>
                    <Reveal.Content hidden className="google_state_2">
                      <Icon name='google' size="large"/>
                    </Reveal.Content>
                  </Reveal>
            </div>
    );
  }
}