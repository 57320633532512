import React, { Component } from "react";
import {Link} from 'react-router-dom'
import styled from "styled-components"

const PromoWrapper = styled.div
`
  position:relative;
  margin-top:20px;
  
`

export default class Promotions extends Component {
  render() {
    return (
      <PromoWrapper>
        <div>
          <h1>Promotions</h1>
          
        </div>
      </PromoWrapper>
    );
  }
}