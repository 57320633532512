import React, { useEffect, useState } from "react";
import {Image, Header, Card,  Label, Container, Grid,  Segment, Icon, Divider} from 'semantic-ui-react';
import {Link, withRouter} from 'react-router-dom';
import {capitalize, has} from 'lodash';
import {LoadProjects} from 'graphql/queryHandlers'
import styled from 'styled-components';
import {useGlobalState} from 'globalstate';
import SmartImage from 'components/SmartImage';
import ImageView from 'components/ImageView';

const Section = styled(Container)
`
    position:relative;
    margin-top:${props=>props.head? props.head : "30px"}px;
    margin-bottom:${props=>props.foot? props.foot : "30px"}px;
    h1.ui.header {
        font-size:18px;
        .sub.header {
            font-size:14px;
        }
    }
    text-align: left;
    font-size:16px;
    line-height:24px;
    p, .header {
        padding:5px 0px;
    }
    &.about {
        .mobile-fullwidth {
            width:100%;
        }
    }
`

const ProjectBlock = styled.div
`
    position:relative;
    margin-top:10px;
    background-color:white;
    .title {
      font-weight:bold;
      margin-bottom:5px;
      margin-top:20px;
    }
    .description {
      
    }
    .image-grid {
              position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2px;
          .image-item {
                position: relative;
    width: 100%;
    height: 138px;
    border-radius: 4px;
    overflow: hidden;
    padding: 2px;
          }
    }
`

function ServiceView (props) {
    const [{browser}, dispatch] = useGlobalState();
    const [service, setService] = useState({});
    const [projects, setProjects] = useState([]);
    const [currentProject, setCurrentProject] = useState({});
    const [currentImage, setCurrentImage] = useState({});
    const [imageViewOpen, setImageViewOpen] = useState(false);
    
    const setStates = (service, projects) =>{
      setService(service);
      setProjects(projects);
    }
    
    useEffect(() => {
       LoadProjects(props.match.params.servicetype, setStates)
       
    }, []);
    
    const openImage = (project, image) =>{
      setCurrentProject(project); 
      setCurrentImage(image); 
      setImageViewOpen(true)
    }
    
    console.log("SERVICE", service);
    console.log("Projects", projects)
    
    return (
      <Section head={0} foot={40} className="project-list">
        <div className="lander">
          <Link className="back-button" to="/"><Icon name="chevron left" /> Back</Link>
          {has(service, 'name') && <Header>{service.name}</Header>}
          {has(service, 'description') && <p>{service.description}</p>}
          {projects.length > 0 && projects.map(project=><ProjectBlock>
            <div className="title">{project.name}</div>
            <div className="description">{project.description}</div>
            <div className="image-grid">
              {project.images && project.images.items.length > 0 && project.images.items.map(image=><div onClick={()=>openImage(project,image)} ><SmartImage className="image-item" imageKey={image.imageKey} size={200} background /></div>)}
            </div>
          </ProjectBlock>)}
        </div>
        <ImageView open={imageViewOpen} project={currentProject} image={currentImage} onModalClose={()=>setImageViewOpen(false)}/>
      </Section>
    );
  
}

export default withRouter(ServiceView)