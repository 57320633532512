import React, { useEffect, useState } from "react";
import {Image, Header, Card,  Label, Container, Grid,  Segment, Icon, Divider} from 'semantic-ui-react';
import {Link, withRouter} from 'react-router-dom';
import {capitalize} from 'lodash';
import {LoadProjects} from 'graphql/queryHandlers'
import styled from 'styled-components';
import {useGlobalState} from 'globalstate';

const Section = styled(Container)
`
    position:relative;
    margin-top:${props=>props.head? props.head : "30px"}px;
    margin-bottom:${props=>props.foot? props.foot : "30px"}px;
    h1.ui.header {
        font-size:18px;
        .sub.header {
            font-size:14px;
        }
    }
    text-align: left;
    font-size:16px;
    line-height:24px;
    p, .header {
        padding:5px 20px;
    }
    &.about {
        .mobile-fullwidth {
            width:100%;
        }
    }
`

function Projects (props) {
    const [{browser}, dispatch] = useGlobalState();
    const [services, setServices] = useState([]);
    const [projects, setProjects] = useState([]);
    
    useEffect(() => {
       LoadProjects(props.match.params.servicetype, setServices)
       
    }, []);
    
    return (
      <Section className="project-list">
        <div className="lander">
          <img src="https://s3-us-west-2.amazonaws.com/acgi-homes/ACGI+Logo+Final.png" alt="ACGI Homes" />
          <Header></Header>
          <abbr style={{textAlign:"center", fontWeight:"bold"}}>Est. 2009</abbr>
        </div>
      </Section>
    );
  
}

export default withRouter(Projects)